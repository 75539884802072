import Glide from "@glidejs/glide";
import Rellax from "rellax";
import Marquee3k from "marquee3000";
import AOS from "aos";

// Airtable for email collection
import Airtable from "airtable"

const rellax = new Rellax('.rellax', {
	breakpoints: [600, 768, 992]
});

window.addEventListener("load", function () {

	Marquee3k.init()

	rellax.refresh();

	AOS.init();

	var glide = new Glide('#testimonials-slider', {
		type: 'carousel',
		focusAt: 'center',
		breakpoints: {
			3000: {
				perView: 3,
				gap: 64,
				peek: 128,
				startAt: 1
			},
			1500: {
				perView: 3,
				gap: 32,
				peek: 64
			},
			1000: {
				perView: 2,
				gap: 16,
				peek: 32
			},
			767: {
				perView: 1,
				gap: 16,
				peek: 32,
				startAt: 0
			}
		}
	})

	glide.mount()

	glide.on
})

const emailInput = document.querySelector('input[type=email]');
const airtable = new Airtable({ apiKey: 'keykcHjzvHq5WKDv2' })
document.querySelector('form').addEventListener('submit', (event) => {
	event.preventDefault();

	const base = airtable.base('appdGkAnlR7PohoY6');

	const Email = emailInput.value
	const Timestamp = String(Date.now())

	if (/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/.test(Email)) {
		base('Table 1').create([
			{
				"fields": { Email, Timestamp }
			}
		], function(err, records) {
			if (err) {
				console.error(err);
				return;
			}

			// Remove form with confirmation
			document.querySelector('form').innerHTML = `<p>Danke für ihr Interesse!</p>`

			records.forEach(function (record) {
				console.log(record.getId());
			});
		});
	}
})

document.querySelectorAll( 'a' )
	.forEach( ( link ) => {
		if ( 'freshjobs.ch' === link.host ) {
			link.search += '?utm_source=landingpage&utm_medium=landingpage&utm_campaign=wiad-2024';
		}
	} );

// Get and set current year.
document.querySelector( '.current-year' ).textContent = new Date().getFullYear();
